@charset "UTF-8";
/*
  CONFIG
*/
/*
  Webfont
*/
/*
  Typographic Setup
*/
/*
  Defaults for boot/reboot.scss
*/
/*
  zindex list
*/
/*
  COMPONENTS
*/
.brand-link {
  transition: background 0.5s ease;
}

.brand-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.btn {
  display: inline-block;
  border: 0;
  padding: 0.5em 2em;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}
.btn:hover {
  text-decoration: none;
}

.btn.border {
  border: 1px solid currentColor;
  position: relative;
}
.btn.border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  border-bottom: 2px solid currentColor;
  transition: width 0.2s ease;
}
.btn.border:hover:after {
  width: 100%;
}

.btn-block {
  width: 100%;
  display: block;
}

.collage + .collage {
  margin-top: 7.5%;
}

.cta-link {
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  color: #807D71;
}
.cta-link .text {
  margin-right: 2em;
  transition: margin 0.5s ease;
}
.cta-link:hover {
  text-decoration: none;
  color: #807D71;
}
.cta-link:hover .text {
  margin-right: 1em;
}
@media (max-width: 767px) {
  .cta-link {
    font-size: 0.75rem;
  }
}

.icon {
  display: inline-block;
}

.icon-inline-w {
  width: 1.5em;
  height: auto;
}

.icon-inline-h {
  height: 1.5em;
  width: auto;
}

.icon-fill path {
  fill: currentColor;
}

.icon-stroke path {
  stroke: currentColor;
}

.img-frame {
  display: flex;
}

.img-fit {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.img-frame-fit {
  height: 100%;
}

@media (min-width: 576px) {
  .sm\:img-frame-fit {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .md\:img-frame-fit {
    height: 100%;
  }
}
@media (min-width: 992px) {
  .lg\:img-frame-fit {
    height: 100%;
  }
}
@media (min-width: 1200px) {
  .xl\:img-frame-fit {
    height: 100%;
  }
}
@keyframes navbar-open {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes navbar-close {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
    display: none;
  }
}
body.navbar-open {
  overflow: hidden;
}
body.navbar-open .navbar {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2300;
}

.navbar {
  font-family: Montserrat, sans-serif;
}

.navbar .nav-link {
  text-decoration: none;
  text-transform: uppercase;
  color: currentColor;
}

.nav-link {
  position: relative;
}
.nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  border-bottom: 2px solid currentColor;
  transition: width 0.2s ease;
}
.nav-link:hover:after {
  width: 100%;
}

.navbar-absolute {
  position: absolute;
  top: 0;
}

@media (max-width: 1199px) {
  .navbar-toggle {
    width: 100%;
    padding-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .navbar-toggle .container-main {
    display: flex;
  }
  .navbar-toggle .toggle-link {
    flex: 0 0 2.5em;
  }
  .navbar-toggle .logo {
    flex: 0 0 auto;
    width: 160px;
    margin: auto;
  }
  .navbar-toggle .logo-img {
    width: 160px;
    height: 109px;
    fill: currentColor;
  }
  .navbar-toggle .logo-img > * {
    fill: currentColor;
  }

  .navbar {
    display: none;
  }

  .navbar.navbar-open {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    color: #FFFFFF;
    background: #000000;
    animation: navbar-open 0.5s ease;
  }

  .navbar.navbar-closing {
    animation: navbar-close 0.2s ease;
    animation-fill-mode: forwards;
  }

  .navbar-close {
    color: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    flex: 0 0 4.5em;
    padding: 2.5rem 1em;
    width: 4.5em;
    height: 7.5em;
  }

  .navbar-container {
    width: 100vw;
    max-width: 100%;
    flex: 0 0 auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .navbar-container .logo {
    flex: 0 0 auto;
    width: 160px;
    margin: auto;
    order: -1;
  }
  .navbar-container .logo-img {
    width: 160px;
    height: 109px;
    fill: currentColor;
  }
  .navbar-container .logo-img > * {
    fill: currentColor;
  }
  .navbar-container .nav-legal,
.navbar-container .nav-left,
.navbar-container .nav-phone {
    margin-top: 75px;
  }
  .navbar-container .nav-phone {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  .navbar-container .nav-link {
    flex: 0 0 auto;
    display: inline-block;
    font-size: 20px;
    padding: 10px;
  }
  .navbar-container .nav-legal .nav-link {
    font-size: 12px;
    padding: 6px;
  }

  .nav {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .navbar-toggle {
    display: none;
  }

  .navbar .navbar-close,
.navbar .nav.nav-phone,
.navbar .nav.nav-legal {
    display: none;
  }

  .navbar {
    width: 100%;
    padding-top: 4.5%;
    margin-bottom: 4.5%;
  }

  .navbar-container {
    display: flex;
    width: 100%;
  }

  .navbar .nav-link {
    font-size: 16px;
  }

  .navbar .nav {
    flex: 1 1 0;
    margin: -20px;
    display: flex;
    align-items: flex-start;
  }

  .nav-left {
    justify-content: flex-start;
  }

  .nav-right {
    justify-content: flex-end;
  }

  .nav .nav-link {
    padding: 5px 10px;
    margin: 20px;
  }

  .nav .border {
    border: 1px solid currentColor;
  }

  .logo {
    flex: 1 1 0;
    display: flex;
  }

  .logo-img {
    width: 360px;
    height: auto;
    margin: auto;
  }
  .logo-img > * {
    fill: currentColor;
  }

  .logo-img-sm {
    width: 160px;
    height: 109px;
  }
}
.pin-canvas {
  position: relative;
}

.pin-img {
  display: block;
  width: 100%;
  height: auto;
}

.pin-btn {
  position: absolute;
  background: transparent;
  border: 0;
  padding: 0.5em;
  border-radius: 9999px;
  transform: translate(-50%, -50%);
}
.pin-btn .icon {
  display: block;
  width: 1rem;
}
@media (min-width: 768px) {
  .pin-btn .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2100;
  display: block;
  max-width: 240px;
  font-family: Ethos, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #000000;
  color: #FFFFFF;
  background-clip: padding-box;
  padding: 0.5em;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0;
}

.popover-header {
  font-family: inherit;
  font-size: inherit;
  display: block;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  color: inherit;
}

.product-list {
  list-style-type: none;
  padding: 0;
}

@media (max-width: 575.98px) {
  .xs\:pull-left {
    width: calc(100% + 1rem) !important;
    margin-left: -1rem !important;
  }

  .xs\:pull-right {
    width: calc(100% + 1rem) !important;
    margin-right: -1rem !important;
  }

  .xs\:pull-up {
    position: relative;
    margin-top: calc(-5vw - 75px) !important;
  }

  .xs\:pull-down {
    position: relative;
    margin-bottom: calc(-5vw - 75px) !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .sm\:pull-left {
    width: calc(100% + 1rem) !important;
    margin-left: -1rem !important;
  }

  .sm\:pull-right {
    width: calc(100% + 1rem) !important;
    margin-right: -1rem !important;
  }

  .sm\:pull-up {
    position: relative;
    margin-top: calc(-5vw - 75px) !important;
  }

  .sm\:pull-down {
    position: relative;
    margin-bottom: calc(-5vw - 75px) !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .md\:pull-left {
    width: calc(100% + 2rem) !important;
    margin-left: -2rem !important;
  }

  .md\:pull-right {
    width: calc(100% + 2rem) !important;
    margin-right: -2rem !important;
  }

  .md\:pull-up {
    position: relative;
    margin-top: calc(-5vw - 75px) !important;
  }

  .md\:pull-down {
    position: relative;
    margin-bottom: calc(-5vw - 75px) !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lg\:pull-left {
    width: calc(100% + 2.5rem) !important;
    margin-left: -2.5rem !important;
  }

  .lg\:pull-right {
    width: calc(100% + 2.5rem) !important;
    margin-right: -2.5rem !important;
  }

  .lg\:pull-up {
    position: relative;
    margin-top: calc(-5vw - 75px) !important;
  }

  .lg\:pull-down {
    position: relative;
    margin-bottom: calc(-5vw - 75px) !important;
  }
}
@media (min-width: 1200px) {
  .xl\:pull-left {
    width: calc(100% + 3rem) !important;
    margin-left: -3rem !important;
  }

  .xl\:pull-right {
    width: calc(100% + 3rem) !important;
    margin-right: -3rem !important;
  }

  .xl\:pull-up {
    position: relative;
    margin-top: calc(-5vw - 75px) !important;
  }

  .xl\:pull-down {
    position: relative;
    margin-bottom: calc(-5vw - 75px) !important;
  }
}
.quote {
  position: relative;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}
@media (min-width: 992px) {
  .quote {
    font-size: 35px;
  }
}
.quote:before, .quote:after {
  font-family: Ethos, serif;
  font-size: 70px;
  line-height: 35px;
  color: #807D71;
}
.quote:before {
  content: "„";
  position: absolute;
  top: -20px;
  left: 0;
}
.quote:after {
  content: "„";
  position: absolute;
  bottom: 12px;
  right: 0;
}

.slider {
  position: relative;
  width: 100%;
}

.slides {
  position: relative;
}

.slide {
  width: 100%;
  height: auto;
}

.control-bar {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #FFFFFF;
  z-index: 2200;
}
.control-bar .container-main {
  display: flex;
  justify-content: space-between;
}

.btn-control {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: inherit;
  border: 0;
  background: transparent;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2600;
  display: none;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  overflow: hidden;
  outline: 0;
}
.modal.backdrop-inline:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.2;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: subtract(100%, 0);
}
.modal-dialog-scrollable .modal-content {
  max-height: subtract(100vh, 0);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: subtract(100%, 0);
}
.modal-dialog-centered::before {
  display: block;
  height: subtract(100vh, 0);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000000;
  background-clip: padding-box;
  border: 0 solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2500;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.2;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid rgba(255, 255, 255, 0.2);
}

.modal-close {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
}
.modal-close > .btn {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
}

.modal-title {
  padding-left: 3rem;
  padding-right: 3rem;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid rgba(255, 255, 255, 0.2);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: subtract(100%, 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: subtract(100vh, 3.5rem);
  }

  .modal-dialog-centered {
    min-height: subtract(100%, 3.5rem);
  }
  .modal-dialog-centered::before {
    height: subtract(100vh, 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0 0 1rem 2rem rgba(0, 0, 0, 0.2), 0 0 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 880px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.cookie-consent {
  position: sticky;
  bottom: 1rem;
  width: 100%;
  padding: 1rem;
  z-index: 2600;
  transition: transform 0.5s ease;
}
.cookie-consent .cookie-consent-body {
  position: relative;
  padding: 2rem;
  background: black;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
}

.cookie-consent-show {
  transform: translateY(0);
}

.cookie-consent-transition {
  transform: translateY(100%);
}

.cookie-consent-hide {
  display: none;
}

.label {
  width: 100%;
  display: block;
  color: inherit;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.label-sm {
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

.input {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 0;
  padding: 0.6rem;
  background: #fff;
  border: 1px solid #000;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  outline: none;
  resize: vertical;
  transition: border-color 0.2s ease;
  color: #000;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}
.input[disabled] {
  border-color: #0d0d0d;
  background-color: #f2f2f2;
  box-shadow: none;
  cursor: not-allowed;
  color: #0d0d0d;
}
.input:focus {
  border-color: #17f;
}
.input::placeholder {
  color: #999;
  opacity: 1;
}
.input:-ms-input-placeholder {
  color: #999;
}
.input::-ms-input-placeholder {
  color: #999;
}

.control {
  position: relative;
  margin: 0 0 0;
  box-sizing: border-box;
}
.control:last-child {
  margin-bottom: 0;
}
.control-input {
  position: absolute;
  opacity: 0;
  appearance: none;
  margin: 0;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
}
.control-label {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: auto;
}
.control-label::before, .control-label::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
}
.control-label::before {
  flex-shrink: 0;
  transition: border-color 0.2s ease;
  margin-right: 0.6rem;
  border-color: #000;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  background: #fff;
}
.control-label::after {
  position: absolute;
  top: 0;
  left: 0;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(0);
  transition: transform 0.2s ease;
}
.control-input[type=radio] + .control-label::before {
  border-radius: 100%;
}
.control-input[type=radio] + .control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20viewBox%3D%220%200%20512%20512%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22M256%2C464c114.9%2C0%2C208-93.1%2C208-208c0-114.9-93.1-208-208-208C141.1%2C48%2C48%2C141.1%2C48%2C256C48%2C370.9%2C141.1%2C464%2C256%2C464z%22/%3E%3C/svg%3E");
}
.control-input[type=checkbox] + .control-label::after {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"%3E%3Cpath fill="none" stroke="%23000000" stroke-linejoin="round" stroke-width="4" d="M5.73 18.25l10.96 10.96M16.08 29.67l18.1-18.1"/%3E%3C/svg%3E');
}
.control-input[type=radio][disabled] + .control-label, .control-input[type=checkbox][disabled] + .control-label {
  cursor: not-allowed;
  color: #0d0d0d;
}
.control-input[type=radio][disabled] + .control-label::before, .control-input[type=checkbox][disabled] + .control-label::before {
  border-color: #0d0d0d;
  background-color: #f2f2f2;
  box-shadow: none;
}
.control-input:focus + .control-label::before {
  border-color: #17f;
}
.control-input:checked + .control-label::after {
  transform: scale(1);
}

.select {
  appearance: none;
  box-sizing: border-box;
  margin: 0 0 0;
  padding: 0.6rem;
  padding-right: calc(0.9rem + 12px);
  width: 100%;
  background: #fff;
  background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20viewBox%3D%220%200%20512%20512%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22M256%2C298.3L256%2C298.3L256%2C298.3l174.2-167.2c4.3-4.2%2C11.4-4.1%2C15.8%2C0.2l30.6%2C29.9c4.4%2C4.3%2C4.5%2C11.3%2C0.2%2C15.5L264.1%2C380.9c-2.2%2C2.2-5.2%2C3.2-8.1%2C3c-3%2C0.1-5.9-0.9-8.1-3L35.2%2C176.7c-4.3-4.2-4.2-11.2%2C0.2-15.5L66%2C131.3c4.4-4.3%2C11.5-4.4%2C15.8-0.2L256%2C298.3z%22/%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.6rem) center;
  border: 1px solid #000;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  outline: 0;
  transition: border-color 0.2s ease;
  color: #000;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}
.select[disabled] {
  border-color: #0d0d0d;
  background-color: #f2f2f2;
  box-shadow: none;
  cursor: not-allowed;
  color: #0d0d0d;
}
.select:focus {
  border-color: #17f;
}
.select::-ms-expand {
  display: none;
}

form {
  font-family: Montserrat, sans-serif;
}

.js-contact-form .js-loader {
  display: none;
}
.js-contact-form .js-success {
  display: none;
}
.js-contact-form.is-loading .js-loader {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  animation: spin 0.75s linear infinite;
}
.js-contact-form.js-sent .js-success {
  display: block;
}

#TheSpecialIngredientField {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.container-news {
  width: 100%;
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .container-news {
    margin-top: -160px;
  }
}
@media (min-width: 992px) {
  .container-news {
    justify-content: flex-end;
    padding-right: 15%;
  }
}
@media (min-width: 1200px) {
  .container-news {
    margin-top: -240px;
  }
}

.news-badge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 320px;
  border-radius: 160px;
  color: #FFFFFF;
  background-color: #807D71;
}
.news-badge .news-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url("/assets/images/news/news-bubble.svg");
  background-size: 100% 100%;
  transform-origin: 50% 50%;
}
.news-badge .news-slide {
  max-height: 100%;
  overflow-y: auto;
  text-align: center;
}
.news-badge .slider {
  z-index: 2;
  padding: 10%;
}

@keyframes news-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.js-news-slider {
  position: static;
}
.js-news-slider .js-slider-controls {
  display: flex;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
.js-news-slider .js-slider-prev,
.js-news-slider .js-slider-next {
  width: 1.5rem;
  height: 1.5rem;
}

/*Helpers*/
.align-flex-end {
  align-self: flex-end;
}

/*Collagen*/
.collage-2 .p-quote {
  font-size: 1.5rem;
  line-height: 1.4;
}